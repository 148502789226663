import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import { tailwindToast } from '../../../../components/Toast/tailwindToast';
import Sidebar from "./sidebar";
import { format } from "date-fns";
import { useToastAction } from '../../../../hooks/useToastAction';

import * as WebEditorApi from "../../../../api/webEditor";
import * as InteractApi from "../../../../api/interact";
import GenericModal from '../../../../components/Modals/genericModal';

import "./styles.scss";
import { useClassNames } from "../../../../hooks/useClassNames";
import { ContentWrapper, CoreButton, PageHeader } from "@metaforcelabs/metaforce-core";


export default function WebEditorSettings() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [allWebEditorSettings, setAllWebEditorSettings] = useState([]);
  const [activeWebEditorSettings, setActiveWebEditorSettings] = useState();
  const [selectedWebEditorSettings, setSelectedWebEditorSettings] = useState([])
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [allCustomerEnvironments, setAllCustomerEnvironments] = useState([])

  const loadAction = useToastAction();
  const saveAction = useToastAction();
  const deleteAction = useToastAction();

  const { classNames } = useClassNames();

  const loadBaseData = async () => {
    loadAction.execute(async () => {
      const environments = await InteractApi.getInteractEnvironments();
      setAllCustomerEnvironments(environments);

      const settings = await WebEditorApi.getWebEditorSettingsByCustomerId();
      setAllWebEditorSettings(settings);
    }, "Failed to load")
  }

  useEffect(() => {
    loadBaseData();
  }, [])

  const handleSidebar = () => {
    if (openSidebar) {
      setActiveWebEditorSettings(null);
    }

    setOpenSidebar(!openSidebar);
  };

  const toggleSelectWebEditorSettings = (settings) => {
    setSelectedWebEditorSettings(prev => {
      const idx = selectedWebEditorSettings.findIndex(x => x === settings);
      if (idx === -1) {
        return [...prev, settings];
      }
      return [...prev.filter(item => item !== settings)];
    })
  }

  const toggleAllWebEditorSettings = () => {
    selectedWebEditorSettings.length === allWebEditorSettings.length ?
      setSelectedWebEditorSettings([]) : setSelectedWebEditorSettings(allWebEditorSettings)
  }

  const handleCreate = async () => {
    const newWebEditorSettings = {
      endFormEnabled: true,
      centralDistributionEnabled: true,
      centralDistributionKivraEnabled: true,
      kivraMethod : 0,
      centralDistributionDigipostEnabled: true,
      digipostMethod : 0,
      centralDistributionCentralprintEnabled: true,
      centralprintMethod: 0,
      archiveOnlyEnabled: true,
      archiveMethod : 0,
      localPrintEnabled: true,
      emailEnabled: true,
      mfdxClientSecret: '',
      textLibraryEnabled: false,
      previewHtmlEnabled: false,
      pdfAttachmentEnabled: true,
      pdfAttachmentDPI: 150,
      pdfAttachmentColorDepth: 1,
      pdfAttachmentDuplex: true,
      environmentScope: '',
      languageCode: 'en',
      selected: false
    }

    if (allCustomerEnvironments.find(({ value }) => allWebEditorSettings.find(settings => settings.customerEnvironment === value) === undefined)) {
      setActiveWebEditorSettings(newWebEditorSettings);
      setOpenSidebar(true);
    } else {
      toast.error("It can not create a new WebEditor settings because all customer environments are already used.");
    }
  }

  const handleSave = async () => {
    if (activeWebEditorSettings.id) {
      const webEditorSettings = await WebEditorApi.getWebEditorSettings(activeWebEditorSettings.id);
      if (webEditorSettings && webEditorSettings.updatedDate !== activeWebEditorSettings.updatedDate) {
        tailwindToast.error("It can not save because WebEditor settings has been updated by others. Please refresh the page.");
        return;
      }
    }

    setOpenSidebar(false)

    saveAction.execute(async () => {
      if (activeWebEditorSettings.id) {
        await WebEditorApi.updateWebEditorSettings(activeWebEditorSettings);
      } else {
        await WebEditorApi.createWebEditorSettings(activeWebEditorSettings);
      }
      await loadBaseData();
    }, "Failed to save WebEditor settings", "WebEditor settings saved")
  }

  const openDelete = async () => {
    setOpenDeleteConfirmation(true);
  }

  const handleDelete = async () => {
    deleteAction.execute(async () => {
      setOpenDeleteConfirmation(false);
      var webEditorSettingsIdsToDelete = selectedWebEditorSettings.map(webEditorSettings => webEditorSettings.id);
      setSelectedWebEditorSettings([]);
      await WebEditorApi.deleteWebEditorSettings(webEditorSettingsIdsToDelete)
      await loadBaseData();
    }, "Failed to delete WebEditor settings", "WebEditor settings deleted")
  }

  const handleWebEditorSettingsRow = (webEditorSettings) => {
    setOpenSidebar(true);
    setActiveWebEditorSettings(webEditorSettings);
  };

  return (
    <>
      <ContentWrapper>
          <PageHeader title="WebEditor Settings" description="The WebEditor settings to specific Interact environments"/>

          {/* Start main area*/}

          <div className="mt-10 shadow-sm overflow-hidden border border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    <input
                      id="selectAll"
                      name="selectAll"
                      type="checkbox"
                      onClick={e => toggleAllWebEditorSettings()}
                      checked={allWebEditorSettings.length > 0 && selectedWebEditorSettings.length === allWebEditorSettings.length}
                      className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                    />
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Customer Environment
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Created by
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {allWebEditorSettings &&
                  allWebEditorSettings.map((webEditorSettings) => (
                    <tr
                      key={webEditorSettings.id}
                      className={classNames(
                        webEditorSettings.id === activeWebEditorSettings?.id
                          ? "bg-gray-100"
                          : "",
                        "hover:bg-gray-100 cursor-pointer"
                      )}
                    >
                      <td className="px-6 w-12 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <input
                          id="selected"
                          name="selected"
                          type="checkbox"
                          checked={selectedWebEditorSettings.findIndex(i => i === webEditorSettings) !== -1}
                          onChange={() => toggleSelectWebEditorSettings(webEditorSettings)}
                          className="focus:ring-brand-pink h-4 w-4 text-brand-pink border-gray-300 rounded"
                        />
                      </td>
                      <td className="px-6 py-4 text-sm font-medium text-gray-900" onClick={() => handleWebEditorSettingsRow(webEditorSettings)}>
                        {allCustomerEnvironments.find(({ value }) => value === webEditorSettings.customerEnvironment)?.name}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleWebEditorSettingsRow(webEditorSettings)}>
                        {format(
                          new Date(webEditorSettings.createdDate),
                          "MM/dd/yyyy - HH:mm"
                        )}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500" onClick={() => handleWebEditorSettingsRow(webEditorSettings)}>
                        {webEditorSettings.createdByName}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className='mt-5 flex items-center'>
            <div className="flex-1" />
            {
              selectedWebEditorSettings.length > 0 && (
              <CoreButton
                type="secondary"
                htmlType="button"
                label={<div>Delete</div>}
                htmlClassNames="mr-3"
                onClick={() => openDelete()}
              />
              )
            }
            <CoreButton
              htmlType="button"
              label={<div>New</div>}
              onClick={handleCreate}
            />
          </div>
          {/* End main area */}
      </ContentWrapper>

      <Sidebar
        open={openSidebar}
        setOpen={handleSidebar}
        webEditorSettings={activeWebEditorSettings}
        allWebEditorSettings={allWebEditorSettings}
        setWebEditorSettings={setActiveWebEditorSettings}
        allCustomerEnvironments={allCustomerEnvironments}
        onSubmit={handleSave}
      />

      <GenericModal open={openDeleteConfirmation} setOpen={setOpenDeleteConfirmation}
        onConfirm={handleDelete}
        onCancel={() => setOpenDeleteConfirmation(false)}
        showCancelButton={true}
        confirmButtonText={'Delete'}
        title="Delete WebEditor Settings">
        <div className="mt-4">
          <p className="text-sm text-gray-500">
            Are you sure you want to delete the selected WebEditor settings?
          </p>
        </div>
      </GenericModal>
    </>
  );
}
