export const invoiceStates = {
    draft: 'Draft',
    open: 'Open',
    paid: 'Paid',
    uncollectible: 'Uncollectible',
    void: 'Void'
}

export const dashboardAreas = {
    team: 'Team',
    archive: 'Archive',
    billing: 'Billing',
    audit: 'Audit'
}

export const adminProductId = "6214a263522afab6930ebd37";
export const adminUsageFeatureCode = "AUC";
export const adminUserFeatureCode = "ASC";
export const adminInvitesFeatureCode = "AIC";
export const adminBillingFeatureCode = "ABC";
export const adminConfigurationFeatureCode = "ACC";