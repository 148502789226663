import React from 'react';
import { Field } from 'formik';
import TextField from '../../../../../components/TextField';

const ProviderTypes = {
  verified: 'verified',
  signicat: 'signicat'
};

const Provider = ({ customerProvider, digitalSigningProviders, formValues }) => {
  return (
    <div className="space-y-6 sm:space-y-5">
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-45 sm:pt-5">
        <label htmlFor="" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
          Provider
        </label>

        <div className="mt-1 sm:col-span-2 sm:mt-0">
          <Field name="provider">
            {({ field }) => (
              <select
                onChange={field.onChange}
                name={field.name}
                value={field.value}
                className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-gray-400 focus:border-gray-400"
              >
                {digitalSigningProviders.map((p) => (
                  <option key={p.providerId} value={p.providerId}>
                    {p.name}
                  </option>
                ))}
              </select>
            )}
          </Field>
        </div>
      </div>

      {
        formValues.provider == ProviderTypes.signicat && (
          <div className="grid grid-cols-12">
            <div className="col-span-12 sm:col-span-4">
              <label className="sm:pt-2 text-sm font-medium text-gray-700 sm:flex items-center">
                Default Notification Email
              </label>
            </div>

            <div className="col-span-12 sm:col-span-8">
              <Field name="defaultNotificationEmail">
                {({ field, form: { errors, touched } }) => (
                  <TextField
                    name={field.name}
                    id="defaultNotificationEmail"
                    value={field.value}
                    error={touched.defaultNotificationEmail ? errors.defaultNotificationEmail : false}
                    onChange={field.onChange}
                  />
                )}
              </Field>
            </div>
          </div>
        )
      }

      {formValues.provider === ProviderTypes.verified && (
        <VerifiedProviderForm currentProviderCredentials={customerProvider?.customCredentials} />
      )}

    </div>
  );
};

const VerifiedProviderForm = ({ currentProviderCredentials }) => {
  return (
    <>
      <div className="sm:pt-3 mt-8 space-y-4">
        <div className="grid grid-cols-12">
          <div className="col-span-12 sm:col-span-4">
            <label className="sm:pt-2 text-sm font-medium text-gray-700 sm:flex items-center">
              UserId
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <Field name="clientId">
              {({ field, form: { errors, touched } }) => (
                <TextField
                  name={field.name}
                  id="clientId"
                  value={field.value}
                  error={touched.clientId ? errors.clientId : false}
                  onChange={field.onChange}
                />
              )}
            </Field>
          </div>
        </div>

        <div className="sm:pt-3 grid grid-cols-12">
          <div className="col-span-12 sm:col-span-4">
            <label className="text-sm font-medium text-gray-700 sm:flex items-center">
              Password
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <Field name="clientSecret">
              {({ field, form: { errors, touched } }) => (
                <>
                  <TextField
                    type="password"
                    name={field.name}
                    id="clientSecret"
                    value={field.value}
                    error={touched.clientSecret ? errors.clientSecret : false}
                    onChange={field.onChange}
                  />

                  <div>
                    <span className="text-sm text-gray-500">
                      {currentProviderCredentials?.isSet && !field.value && 'This value is hidden'}
                    </span>
                  </div>
                </>
              )}
            </Field>
          </div>
        </div>

        <div className="sm:pt-3 grid grid-cols-12">
          <div className="col-span-12 sm:col-span-4">
            <label className="text-sm font-medium text-gray-700 sm:flex items-center">
              CompanyId
            </label>
          </div>

          <div className="col-span-12 sm:col-span-8">
            <Field name="companyId">
              {({ field, form: { errors, touched } }) => (
                <TextField
                  name={field.name}
                  id="companyId"
                  value={field.value}
                  error={touched.companyId ? errors.companyId : false}
                  onChange={field.onChange}
                />
              )}
            </Field>
          </div>
        </div>
      </div>
    </>
  );
};

export default Provider;
