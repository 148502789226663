import { useReactOidc } from '@axa-fr/react-oidc-context';
import { createCustomer } from '../../../../api/customer';
import React, { useEffect, useState } from 'react'
import { useHistory, } from 'react-router-dom'
import CompanyContactsForm from './companyContactsForm';
import CompanyDetailsForm from './companyDetailsForm';
import { getCountries } from '../../../../api/country';
import { changeCompany } from '../../../../utils/oidcUtilities';

export default function Create() {
    const [countries, setCountries] = useState([]);
    const { oidcUser, logout } = useReactOidc();
    const [registrationStage, setRegistrationStage] = useState(1);
    const [registerForm, setRegisterForm] = useState();
    const [createdCustomerId, setCreatedCustomerId] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const handleCompanyDetailsSubmit = async (form) => {
        // const response = await createCustomer(oidcUser.access_token, form);
        setRegisterForm(form);
        // console.log(response);
        // setCreatedCustomerId(response);
        setRegistrationStage(2);
    }
    const handleAddInfoSubmit = async (updated) => {
        const updatedForm = {
            ...registerForm,
            ...updated,
            country: countries.find(x => x.code === registerForm.country)
            // customerId: createdCustomerId
        };
        console.log(updatedForm);
        // valiidate
        try {
            setErrorMessage(null);
            const response = await createCustomer(oidcUser.access_token, updatedForm);
            console.log(response);
            setRegistrationStage(3);
        } catch (error) {
            setErrorMessage('Customer creation failed');
        }
        // const response = await updateCustomer(oidcUser.access_token, updatedForm);

    }
    const loadData = async () => {
        const serverCountries = await getCountries();
        setCountries(serverCountries);
        setRegisterForm({ orgNumber: '', companyName: '', country: serverCountries[0].code })
    }
    useEffect(() => {
        loadData();
    }, [])
    function RegistrationLayout({ h1, h2, children }) {
        return <>
            <div>
                <h1 className="text-3xl text-gray-900 font-bold mb-3">{h1}</h1>
                <h3 className="mt-6 text-xl text-gray-900">
                    {h2}
                </h3>
            </div>
            <div className="mt-6 sm:mt-8">
                {children}
            </div>
        </>
    }
    const FinishCreateCompanyButton = () => {
        return <button name="button"
            type='button'
            onClick={() => {
                changeCompany();
            }}
            className="sm:col-end-3 mt-4 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-400 hover:bg-red-300 focus:outline-none">
            Finish
        </button>
    }

    return (
        <>
            {
                registerForm && registrationStage === 1 && (
                    <RegistrationLayout h1="Let's get started" h2="Lets get your company registered through this quick company registration process in
                    order to manage your Customer Communication tools">
                        <CompanyDetailsForm onValidSubmit={handleCompanyDetailsSubmit} initialValues={registerForm} countries={countries} />
                    </RegistrationLayout>
                )
            }
            {
                registrationStage === 2 && (
                    <RegistrationLayout h1="Just one more step" h2="This will help us tailor our services to your company">
                        <CompanyContactsForm onValidSubmit={handleAddInfoSubmit} onGoToPrev={() => {
                            setRegistrationStage(1);
                        }} />
                    </RegistrationLayout>
                )
            }
            {
                registrationStage === 3 && (
                    <RegistrationLayout h1="Registration Complete" h2={'Congratulations with registration of ' + registerForm.companyName + ' into Metaforce Centerpoint - your customer communications solution.'}>
                        <div className="mt-2 grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <FinishCreateCompanyButton />
                        </div>
                    </RegistrationLayout>
                )
            }
            <div className="text-red-400">
                {errorMessage}
            </div>
        </>


    )
}
