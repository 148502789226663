import { useContext } from "react";
import { AuthenticatedRoutesContext } from "../contexts";
import { adminProductId } from "../utils/constants";

export const useHasFeature = () => {
    const authContext = useContext(AuthenticatedRoutesContext);
    return {
        hasFeature: (featureCode) => {
            if (!featureCode)
                return true;
    
            const product = authContext.centerPointUser.customerUserAccess?.productFeatureAccessSelections?.find(x => x.productId === adminProductId);
            const ret = product?.featureSelections.find(x => x.featureCode === featureCode)?.hasAccess;
            return !!ret;
        }
    }

}