import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getPlanTier } from '../../../api/plan';
import { CheckIcon as CheckIconSolid, } from '@heroicons/react/solid';
import { DefaultLayoutContext } from '../../../contexts';
import { useToastAction } from '../../../hooks/useToastAction';

export default function PurchaseSuccess() {
    const defaultLayoutContext = useContext(DefaultLayoutContext);
    const [currencyPrice, setCurrencyPrice] = useState();
    const { planId } = useParams();
    const [planTier, setPlanTier] = useState();
    const loadAction = useToastAction();
    const loadPlanTier = async () => {

        loadAction.execute(async () => {
            const plan = await getPlanTier(planId);
            const price = plan.planPrices.find(x => x.currency === defaultLayoutContext.customerDetails.country.currency);
            setCurrencyPrice(price);
            setPlanTier(plan);            
        }, 'Failed to load plan')        
    }
    useEffect(() => {
        // setTimeout(()=> defaultLayoutContext.triggerContextChange(), 2000 )
        setTimeout(()=> defaultLayoutContext.triggerContextChange(), 4000 )
        loadPlanTier();
    }, [planId])
    return (
        <>
            {planTier && (
                <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="bg-gradient-to-b from-white to-gray-50">
                        <div className="pt-12 md:pt-18 px-4 sm:px-6 lg:px-8 max-w-2xl mx-auto divide-solid divide-y">
                            <div className="max-w-3xl mx-auto pb-2 sm:pb-4">
                                <h2 className="text-3xl font-extrabold text-gray-900">Thank you for subscribing</h2>
                                <div className="max-w-3xl mx-auto mt-8">
                                    <h2 className="text-xl font-extrabold text-gray-900">Order Details</h2>
                                </div>
                            </div>
                            <div className="pt-2 sm:pt-4 md:pt-8 divide-solid divide-y">
                                <div className="grid grid-cols-2 gap-2 pb-2 sm:pb-4">
                                    <div><h2 className="text-lg leading-6 font-medium text-gray-900">Product</h2></div>
                                    <div><h2 className="text-lg leading-6 font-medium text-gray-900">Price</h2></div>
                                    <div>{planTier.planName}</div>
                                    <div>
                                        <span className="text-gray-900">
                                            {currencyPrice.price} <span className="text-gray-500">{currencyPrice.currency}</span> </span>{' '}
                                        <span className="text-gray-500">/mo</span>
                                    </div>
                                </div>
                                <div className="pt-2 sm:pt-4 md:pt-8 pb-2 sm:pb-4">
                                    <h2 className="text-lg leading-6 font-medium text-gray-900">Product Features</h2>
                                    <ul className="inline-block mt-2 sm:mt-4 md:mt-6 space-y-4 sm:mx-auto">
                                        {planTier.whatsIncluded.map((feature) => (
                                            <li key={feature} className="flex space-x-3">
                                                <CheckIconSolid className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" />
                                                <span className="text-sm text-gray-500">{feature}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>)
            }
        </>
    )
}