import { useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import { useClassNames } from "../../hooks/useClassNames";
import { Switch } from '@headlessui/react';

/**
 * @param {*} SidebarToggle 
 */
export const SidebarToggle = ({ label, description, value, onChange, disabled, error }) => {
  const { classNames } = useClassNames();

  return (
    <>
      <div className="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-3 sm:px-6 sm:py-1">
        <div className="sm:col-span-2">
          <Switch.Group as="div" className="flex items-center justify-between" disabled={disabled}>
            <span className="flex-grow flex flex-col">
              <Switch.Label as="span" className="text-sm font-medium text-gray-900" passive>
                {label}
              </Switch.Label>
              {
                description &&
                <Switch.Description as="span" className="text-sm text-gray-500">
                  {description}
                </Switch.Description>
              }
            </span>
            <Switch
              checked={value}
              onChange={onChange}
              className={classNames(
                value ? 'bg-brand-pink' : 'bg-gray-200',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-pink'
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  value ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
              />
            </Switch>
          </Switch.Group>
          {
            error &&
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {error}
            </p>
          }
        </div>

      </div>

    </>
  );
}