import GenericSidebar from "../../../../components/Modals/genericSidebar";
import {SidebarTextbox} from "../../../../components/SidebarTextbox";
import {useCallback, useEffect, useState} from "react";
import {SidebarSelect} from "../../../../components/SidebarSelect";
import {SidebarCheckbox} from "../../../../components/SidebarCheckbox";

const lifetimeDaysOptions = [{
  name: '1 day',
  value: '1'
}, {
  name: '7 days',
  value: '7'
}, {
  name: '14 days',
  value: '14'
}, {
  name: '30 days',
  value: '30'
}, {
  name: '90 days',
  value: '90'
}, {
  name: '180 days',
  value: '180'
}, {
  name: '1 year',
  value: '365'
}, {
  name: '5 years',
  value: '1825'
}, {
  name: '10 years',
  value: '3650'
}, {
  name: '20 years',
  value: '7300'
}];

const Sidebar = ({policy, open, toggleOpen, onSave}) => {
  const [values, setValues] = useState({
    name: '',
    lifetimeDays: '1',
    isDisabled: false
  });

  const handleSidebar = useCallback(() => {
    toggleOpen(!open);
  }, [open])

  const onSelectChange = useCallback((event) => {
    handleOnChange(event.target.name, event.target.value)
  }, []);

  const handleOnChange = useCallback((name, value) => {
    setValues(prevState => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  const handleOnSave = useCallback(() => onSave(values), [values]);

  useEffect(() => {
    setValues({
      name: policy.name,
      lifetimeDays: policy.lifetimeDays,
      isDisabled: policy.isDisabled
    })
  }, [policy])

  return (
    <>
      <GenericSidebar
        open={open}
        setOpen={handleSidebar}
        title={policy.id.length > 0 ? "Editing" : "Creating new"}
      >
        <div className="flex w-full overflow-y-auto h-full">
          <div className="w-full">
            <div className="py-6 space-y-6 sm:py-2 sm:space-y-3 sm:divide-y sm:divide-gray-200">
              <SidebarTextbox
                label="Name"
                name="name"
                defaultValue={policy ? policy.name : ''}
                onChange={event => handleOnChange('name', event.target.value)}
              />
            </div>

            <div className="py-6 space-y-6 sm:py-2 sm:space-y-3 sm:divide-y sm:divide-gray-200">
              <SidebarSelect
                name="lifetimeDays"
                value={values.lifetimeDays}
                label="Delete records that are older than"
                onChange={onSelectChange}
                options={lifetimeDaysOptions}
                disabled={policy.id.length > 0}
              />
            </div>

            <div className="py-6 space-y-6 sm:py-2 sm:space-y-3 sm:divide-y sm:divide-gray-200">
              <SidebarCheckbox
                name="isDisabled"
                value={values.isDisabled}
                label="Disable policy"
                onChange={event => handleOnChange('isDisabled', event.target.checked)}
              />
            </div>
          </div>
        </div>

        <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
          <div className="space-x-3 flex justify-end">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
              onClick={handleSidebar}
            >
              Cancel
            </button>
            <button
              type="button"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"
              onClick={handleOnSave}
            >
              Submit
            </button>
          </div>
        </div>
      </GenericSidebar>
    </>
  )
}

export default Sidebar;