import { Formik } from 'formik';
import React from 'react'
import { ToggleSwitchField } from '../../../../components/CheckboxField';

const clientTypes = {
    standard: 1,
    externalSystem: 2,
    audit: 4,
    metaTool: 5
};

const clientTypeNames = {
    [clientTypes.standard]: 'Standard',
    [clientTypes.externalSystem]: 'External System',
    [clientTypes.audit]: 'Audit',
    [clientTypes.metaTool]: 'MetaTool'
};

const clientTypeDescription = {
    [clientTypes.standard]: 'Standard api client. Scope: api.external',
    [clientTypes.externalSystem]:
        'For integration purposes from 3rd party systems such as salesforce. This has a limited scope. Scope: api.integration_customer',
    [clientTypes.audit]:
        'For integration limited to posting audit logs only. Very limited scope: Scope: api.audit',
    [clientTypes.metaTool]:
        'For integration limited to creating MetaTool documents only. Very limited scope: Scope: api.metatool'    
};

const webeditEnvironment = {
    dev: 1,
    test: 2,
    stage: 3,
    demo: 4,
    prod: 5
  }
  
  const webeditEnvironmentNames = {
    [webeditEnvironment.dev]: 'Development',
    [webeditEnvironment.test]: 'Test',
    [webeditEnvironment.stage]: 'Stage',
    [webeditEnvironment.demo]: 'Demo',
    [webeditEnvironment.prod]: 'Production'
  }
  
  const webeditEnvironmentScope = {
    [webeditEnvironment.dev]: 'webeditor.dev',
    [webeditEnvironment.test]: 'webeditor.test',
    [webeditEnvironment.stage]: 'webeditor.stage',
    [webeditEnvironment.demo]: 'webeditor.demo',
    [webeditEnvironment.prod]: 'webeditor.prod',
  }

export default function ApiClientForm({ onCreate }) {


    const validateCreateClientForm = (values, props) => {
        const errors = {}

        if (!values.clientName) {
            errors.clientName = "Required"
        }

        if (values.useMtls && !values.thumbprint) {
            errors.thumbprint = "Required"
        }

        return errors;
    }

    return (
        <div>
            <Formik
                initialValues={{
                    clientName: '',
                    clientType: 1,
                    useMtls: false,
                    thumbprint: ''
                }}
                onSubmit={async (values, { setSubmitting }) => {
                    values = { ...values, clientType: +values.clientType };
                    if (!values.useMtls) {
                        values.thumbprint = '';
                    }
                    onCreate(values, () => {
                        setSubmitting(false);
                        // setShowCreateModal(false);
                    })

                    // await handleCreateApiClient(values, () => {
                    //     setSubmitting(false);

                    // });
                }}
                validate={validateCreateClientForm}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                    validateForm
                    /* and other goodies */
                }) => (
                    <form
                        className="text-left space-y-3"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(values);
                        }}
                    >
                        <div>
                            <label
                                htmlFor="firstName"
                                className="block text-sm font-medium text-blue-gray-900"
                            >
                                Client Name
                            </label>
                            <input
                                type="text"
                                name="clientName"
                                id="clientName"
                                autoComplete="false"
                                value={values.clientName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                required
                                disabled={isSubmitting}
                                className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                            <div className="mt-1 text-sm text-red-600">{touched.clientName ? errors?.clientName : ''}</div>
                        </div>
                        <div>
                            <ToggleSwitchField

                                label="Mutual TLS Client Authentication"
                                name="useMtls"
                                value={values.useMtls}
                                onChange={e => {
                                    handleChange(e)
                                }}
                            // error={errors?.useMtls}
                            />
                        </div>

                        {
                            values.useMtls && (
                                <div>
                                    <label
                                        htmlFor="thumbprint"
                                        className="block text-sm font-medium text-blue-gray-900"
                                    >
                                        Certificate Thumbprint
                                    </label>
                                    <input
                                        type="text"
                                        name="thumbprint"
                                        id="thumbprint"
                                        autoComplete="false"
                                        value={values.thumbprint}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        disabled={isSubmitting}
                                        className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <div className="mt-1 text-sm text-red-600">{touched.thumbprint ? errors?.thumbprint : ''}</div>
                                </div>
                            )
                        }

                        <div>
                            <label
                                htmlFor="firstName"
                                className="block text-sm font-medium text-blue-gray-900"
                            >
                                Client Type
                            </label>
                            {/* <input
                                        type="text"
                                        name="clientName"
                                        id="clientName"
                                        autoComplete="false"
                                        value={values.clientType}
                                        onChange={handleChange}
                                        required
                                        disabled={isSubmitting}
                                        className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border-gray-300 rounded-md"
                                    /> */}
                            <select
                                name="clientType"
                                id="clientType"
                                value={values.clientType}
                                onChange={handleChange}
                                disabled={isSubmitting}
                                className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border-gray-300 rounded-md"
                            >
                                {Object.keys(clientTypeNames).map((key) => (
                                    <option value={key}>{clientTypeNames[key]}</option>
                                ))}
                            </select>
                            <div className="mt-2 text-sm text-gray-500">
                                {clientTypeDescription[values.clientType]}
                            </div>
                        </div>
                        <div>
                            <ToggleSwitchField

                                label="Webeditor Environment Scope (optional)"
                                name="useWebeditorEnvScope"
                                value={values.useWebeditorEnvScope}
                                onChange={e => {
                                    handleChange(e)
                                }}
                            // error={errors?.useMtls}
                            />
                        </div>
                        {
                            values.useWebeditorEnvScope && (
                                <div>
                                    <label
                                        htmlFor="webeditorScope"
                                        className="block text-sm font-medium text-blue-gray-900"
                                    >
                                        Webeditor Scope
                                    </label>
                                    {/* <input
                                        type="text"
                                        name="clientName"
                                        id="clientName"
                                        autoComplete="false"
                                        value={values.clientType}
                                        onChange={handleChange}
                                        required
                                        disabled={isSubmitting}
                                        className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border-gray-300 rounded-md"
                                    /> */}
                                    <select
                                        name="webeditorScope"
                                        id="webeditorScope"
                                        value={values.webeditorScope}
                                        onChange={handleChange}
                                        disabled={isSubmitting}
                                        className="disabled:opacity-50 mt-1 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:opacity-50 block w-full sm:text-sm border-gray-300 rounded-md"
                                    >
                                        {Object.keys(webeditEnvironmentNames).map((key) => (
                                            <option value={key}>{webeditEnvironmentNames[key]}</option>
                                        ))}
                                    </select>
                                    <div className="mt-2 text-sm text-gray-500">
                                        Webeditor Scope: {webeditEnvironmentScope[values.webeditorScope]}
                                    </div>
                                </div>
                            )
                        }
                        <div>
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    onClick={() => {
                                        // setShowCreateModal(true)
                                    }}
                                    className="inline-flex items-center px-4 py-2 shadow-sm font-medium rounded-md hover:bg-brand-pink-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-sm bg-brand-pink text-white"
                                >
                                    Create
                                </button>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}
