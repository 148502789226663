import {
  ChevronRightIcon,
  FolderIcon,
  HomeIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
  ViewGridIcon,
  ViewListIcon
} from '@heroicons/react/outline';
import MenuContextList from '../../../../../components/MenuContextList';
import React, { useEffect, useState } from 'react';
import { useClassNames } from '../../../../../hooks/useClassNames';
import { useToastAction } from '../../../../../hooks/useToastAction';
import * as DigitalArchiveApi from '../../../../../api/digitalarchive';
import EditFolder from './EditFolder';
import { getRetentionPoliciesApi } from '../../../../../api/retentionPolicies';
import { CoreButton, PageHeader } from '@metaforcelabs/metaforce-core';

const viewModes = {
  list: 'list',
  grid: 'grid'
};

const Folders = () => {
  const { classNames } = useClassNames();
  const fetchAction = useToastAction();
  const saveAction = useToastAction();
  const editAction = useToastAction();
  const deleteAction = useToastAction();
  const accessGroupsAction = useToastAction();
  const retentionPoliciesAction = useToastAction();

  const [allAccessGroups, setAllAccessGroups] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [activeModelItem, setActiveModalItem] = useState();
  const [folders, setFolders] = useState([]);
  const [foldersBreadcrumbs, setFoldersBreadcrumbs] = useState([]);
  const [viewMode, setViewMode] = useState(viewModes.grid);
  const [retentionPolicies, setRetentionPolicies] = useState([]);

  const getRetentionPolicies = () => {
    retentionPoliciesAction.execute(async () => {
      const getRetentionPoliciesResult = await getRetentionPoliciesApi();

      setRetentionPolicies(getRetentionPoliciesResult);
    }, 'Failed to load retention policies');
  };

  const getAccessGroups = () => {
    accessGroupsAction.execute(async () => {
      const getAccessGroupsResult = await DigitalArchiveApi.getAccessGroupsApi();
      setAllAccessGroups(getAccessGroupsResult);
    }, 'Error');
  };

  const fetchFolders = (id) => {
    fetchAction.execute(async () => {
      const getFoldersResult = await DigitalArchiveApi.getFolders(id);
      setFolders(getFoldersResult);
    }, 'Failed to load folders');
  };

  const saveFolder = async (values, currentFolderId) => {
    saveAction.execute(
      async () => {
        await DigitalArchiveApi.addFolders({
          ...values,
          parentId: currentFolderId || null
        });

        await fetchFolders(currentFolderId || null);
      },
      'Failed to save folder',
      'New folder added'
    );
  };

  const editFolder = async (values, currentFolderId) => {
    editAction.execute(
      async () => {
        await DigitalArchiveApi.updateFolder({
          ...values,
          parentId: currentFolderId || null
        });

        await fetchFolders(currentFolderId || null);
      },
      'Failed to edit folder',
      'Folder edited'
    );
  };

  const handleAddNew = () => {
    setActiveModalItem(null);
    setOpenSidebar(true);
  };

  const handleOnFolderClick = (folder) => {
    fetchFolders(folder.id);
    setFoldersBreadcrumbs((state) => [...state, folder]);
  };

  const handleOnBreadcrumbClick = (folder) => {
    if (folder) {
      const index = foldersBreadcrumbs.findIndex((breadcrumb) => breadcrumb.name === folder.name);

      setFoldersBreadcrumbs((state) => state.slice(0, index + 1));

      fetchFolders(folder.id);
    } else {
      setFoldersBreadcrumbs(() => []);
      fetchFolders();
    }
  };

  const handleEdit = (folder) => {
    setActiveModalItem(folder);
    setOpenSidebar(true);
  };

  const handleDelete = (folder) => {
    const currentFolder = foldersBreadcrumbs[foldersBreadcrumbs.length - 1];

    deleteAction.execute(
      async () => {
        await DigitalArchiveApi.deleteFolder([folder.id]);
        await fetchFolders(currentFolder?.id || null);
      },
      'Failed to delete selected items',
      'Folder deleted'
    );
  };

  const handleChangeView = (mode) => {
    setViewMode(mode);
  };

  const handleOnSaveFolder = async (values) => {
    const currentFolder = foldersBreadcrumbs[foldersBreadcrumbs.length - 1];

    if (activeModelItem?.id) {
      await editFolder(values, currentFolder?.id);
    } else {
      await saveFolder(values, currentFolder?.id);
    }

    setOpenSidebar(false);
  };

  const handleToggleOpen = (value) => {
    setOpenSidebar(value);
  };

  useEffect(() => {
    getRetentionPolicies();
    fetchFolders();
    getAccessGroups();
  }, []);

  return (
    <>
      <PageHeader 
        type="secondary" 
        title="Folders" 
        description={<>You can manage folders that are avaliable to your company in archive. Here you can also manage access roles to them.</>} 
        optionalSideElement={
          <CoreButton 
            label={            
              <div className="flex">
                <PlusIcon className="mr-2 h-4 w-5" aria-hidden="true" />
                <div className="mx-auto">Add new folder</div>
              </div>
            } 
            htmlType="button" onClick={handleAddNew}
          />
        } 
      />
      {folders.length > 0 && (
        <div className="flex my-6">
          <nav className="flex" aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
              <li className="flex items-center">
                <button
                  className="text-gray-400 hover:text-gray-500"
                  onClick={() => handleOnBreadcrumbClick()}
                >
                  <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Home</span>
                </button>
              </li>

              {foldersBreadcrumbs.map((breadcrumb, i) => (
                <li key={breadcrumb.name}>
                  <div className="flex items-center">
                    <ChevronRightIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {i + 1 === foldersBreadcrumbs.length ? (
                      <span className="ml-4 text-sm font-medium text-gray-700 cursor-default">
                        {breadcrumb.name}
                      </span>
                    ) : (
                      <button
                        className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                        onClick={() => handleOnBreadcrumbClick(breadcrumb)}
                      >
                        {breadcrumb.name}
                      </button>
                    )}
                  </div>
                </li>
              ))}
            </ol>
          </nav>

          <div className="flex ml-auto">
            <button className="" onClick={() => handleChangeView(viewModes.list)}>
              <ViewListIcon
                className={classNames(
                  'h-5 w-5 text-gray-400 hover:text-gray-600',
                  viewMode === viewModes.list && 'text-gray-600'
                )}
                aria-hidden="true"
              />
            </button>
            <button className="ml-4" onClick={() => handleChangeView(viewModes.grid)}>
              <ViewGridIcon
                className={classNames(
                  'h-5 w-5 text-gray-400 hover:text-gray-600',
                  viewMode === viewModes.grid && 'text-gray-600'
                )}
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      )}

      <div>
        {folders.length > 0 && (
          <>
            <ul
              role="list"
              className={classNames(
                'mt-3 grid',
                viewMode === viewModes.list && 'grid-cols-1',
                viewMode === viewModes.grid &&
                  'grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-3'
              )}
            >
              {folders.map((folder) => (
                <li
                  key={folder.name}
                  className={classNames(
                    'col-span-1 flex cursor-pointer',
                    viewMode === viewModes.list && 'border-b',
                    viewMode === viewModes.grid && 'bg-white shadow sm:rounded-lg'
                  )}
                >
                  <div
                    className={classNames(
                      'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md',
                      viewMode === viewModes.list && '',
                      viewMode === viewModes.grid && 'bg-white'
                    )}
                    onClick={() => handleOnFolderClick(folder)}
                  >
                    <FolderIcon className="w-5 h-5 text-gray-500" aria-hidden="true" />
                  </div>

                  <div className="flex-1 flex items-center justify-between">
                    <div
                      className="flex-1 px-4 py-4 text-sm truncate"
                      onClick={() => handleOnFolderClick(folder)}
                    >
                      <button className="text-gray-900 font-medium hover:text-gray-600">
                        {folder.name}
                      </button>
                    </div>

                    <div className="relative flex content-center pr-2">
                      {viewMode === viewModes.list ? (
                        <>
                          <button className="" onClick={() => handleEdit(folder)}>
                            <PencilIcon
                              className="h-5 w-5 text-gray-400 hover:text-gray-600"
                              aria-hidden="true"
                            />
                          </button>
                          <button className="ml-4" onClick={() => handleDelete(folder)}>
                            <TrashIcon
                              className="h-5 w-5 text-gray-400 hover:text-gray-600"
                              aria-hidden="true"
                            />
                          </button>
                        </>
                      ) : (
                        <MenuContextList
                          actions={[
                            {
                              name: 'Edit',
                              onClick: () => {
                                handleEdit(folder);
                              }
                            },
                            {
                              name: 'Delete',
                              textClassNames: ' text-red-600 font-medium',
                              onClick: () => {
                                handleDelete(folder);
                              }
                            }
                          ]}
                        />
                      )}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </>
        )}
        {folders.length === 0 && !fetchAction.isExecuting && (
          <div className="mt-6 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">No folders</h3>
            <p className="mt-1 text-sm text-gray-500">Get started by adding a new folder.</p>
          </div>
        )}
      </div>

      <EditFolder
        folder={activeModelItem}
        open={openSidebar}
        allAccessGroups={allAccessGroups}
        retentionPolicies={retentionPolicies}
        onSave={handleOnSaveFolder}
        toggleOpen={handleToggleOpen}
      />
    </>
  );
};

export default Folders;
