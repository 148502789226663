import { XCircleIcon } from "@heroicons/react/outline";
import { useParams } from "react-router-dom";
import { useState } from "react";
import * as yup from "yup";

import { SidebarSelect } from "../../../../components/SidebarSelect";
import { SidebarTextArea } from "../../../../components/SidebarTextArea";
import { SidebarTextbox } from "../../../../components/SidebarTextbox";

export default function Properties({ document, allDocuments, canEdit, onChange, setOpen, onSubmit }) {
  const [errors, setErrors] = useState({})

  let schema = yup.object().shape({
    type: yup.string()
      .required("Type is required")
      .test(
       'type',
       'Type is not unique',
       (value, context) => !allDocuments.some(m => m.type === value && m.id !== document.id),
     ),
    name: yup.string().required("Name is required"),
    topic: yup.string().required("Topic is required"),
    category: yup.string().required("Category is required").min(1, "Category is required"),
    retentionPolicy: yup.string().required("Retention policy is required").min(1, "Retention policy is required"),
    adminCompany: yup.string().required("Admin company is required").min(1, "Admin company is required"),
    plexmode: yup.string().required("Plexmode is required").min(1, "Plexmode is required"),
    colormode: yup.string().required("Colormode is required").min(1, "Colormode is required"),
    postmode: yup.string().required("Postmode is required").min(1, "Postmode is required"),
    internalOnly: yup.string().required("Internal Only is required").min(1, "Internal Only is required"),
    changesAllowed: yup.string().required("Changes Allowed is required").min(1, "Changes Allowed is required"),
    distributionMethod: yup.string().required("Distribution method is required").min(1, "Distribution method is required"),
  });

  const categoriesOptions = [ 
    { name: "Försäkringsbesked", value: "Försäkringsbesked" },
    { name: "Pensionsbesked", value: "Pensionsbesked" },
    { name: "Utbetalningsspec", value: "Utbetalningsspec" },
    { name: "Kontrolluppgift", value: "Kontrolluppgift" },
    { name: "Förmånsbeskrivning", value: "Förmånsbeskrivning" },
    { name: "Förmånsöversikt", value: "Förmånsöversikt" },
    { name: "Överskottsbrev", value: "Överskottsbrev" },
    { name: "Utbetalningsbrev", value: "Utbetalningsbrev" },
    { name: "Uppsägningsbrev INDIVID", value: "Uppsägningsbrev INDIVID", },
    { name: "Uppsägningsbrev FÖRETAG", value: "Uppsägningsbrev FÖRETAG",},
    { name: "Årligt försäkringsbesked", value: "Årligt försäkringsbesked",},
    { name: "Kundavtal", value: "Kundavtal" },
    { name: "Bokföringsuppgift", value: "Bokföringsuppgift" },
    { name: "Årsbesked", value: "Årsbesked" },
    { name: "Rådgivning", value: "Rådgivning" },
    { name: "Flytt", value: "Flytt" },
    { name: "SPP Konsult", value: "SPP Konsult" },
    { name: "Fondhandel", value: "Fondhandel" },
    { name: "Försäkringsbevis", value: "Försäkringsbevis" },
    { name: "Avisering", value: "Avisering" },
    { name: "Återköp", value: "Återköp" },
    { name: "Betalningspåminnelse", value: "Betalningspåminnelse" },
    { name: "Autogirouppdrag", value: "Autogirouppdrag" },
    { name: "Bekräftelsebrev", value: "Bekräftelsebrev" },
    { name: "Valcentral", value: "Valcentral" },
    { name: "Nollbrev", value: "Nollbrev" },
    { name: "Inbetalning", value: "Inbetalning" },
    { name: "Livsfall", value: "Livsfall" },
    { name: "Deklarationsunderlag", value: "Deklarationsunderlag" },
    { name: "Utbetalning", value: "Utbetalning" },
    { name: "Brev", value: "Brev" },
    { name: "Extra brevutskick", value: "Extra brevutskick" },

    { name: "Skadereglering - Risk", value: "Skadereglering - Risk" },
    { name: "Nyteckning", value: "Nyteckning" },
    { name: "Ändring", value: "Ändring" },
    { name: "Skadereglering - Sjuk", value: "Skadereglering - Sjuk" },
    { name: "Okänt dokument", value: "Okänt dokument" },
    { name: "Löneväxling", value: "Löneväxling" },
    { name: "FATCA", value: "FATCA" },
    { name: "Dödsfall", value: "Dödsfall" },
    { name: "Premier", value: "Premier" },
    { name: "Arkivering", value: "Arkivering" },
    { name: "Fullmakt", value: "Fullmakt" },
    { name: "Faktura", value: "Faktura" },
    { name: "Beslut", value: "Beslut" },
    { name: "Kundklagomålsärenden", value: "Kundklagomålsärenden" },
    { name: "Känsliga personuppgifter", value: "Känsliga personuppgifter" },
    { name: "SPP Spar", value: "SPP Spar" },
    { name: "Upphandling", value: "Upphandling" },
    { name: "Migrerat övrigt", value: "Migrerat övrigt" },
    { name: "Elektronisk signatur", value: "Elektronisk signatur" },
    { name: "Makulerad", value: "Makulerad" },
    { name: "Hållbarhetsrapport", value: "Hållbarhetsrapport" },
    { name: "Adressändring", value: "Adressändring" },
  ].sort((a, b) => a.name.localeCompare(b.name)); 

  const validate = async () => {
    var isValid = false

    await schema
      .validate(document, {abortEarly: false})
      .then(function () {
        isValid = true;
        setErrors({});
      })
      .catch(function (err) {
        var newErrors = {}

        err?.inner?.forEach(element => {
          newErrors[element.path] = `${element.errors[0]}`
        });

        setErrors(newErrors);
      });

      return isValid;
  }

  const handleSubmit = async () => {
    var isValid = await validate()
    
    if(isValid)
      onSubmit();
  }

  return (
    <>
      <div className="flex w-full overflow-y-auto h-full">
        <div className="w-full">
        {!canEdit && (
          <div className="rounded-md bg-red-50 p-4 m-4 mb-0">
            <div className="flex">
              <div className="flex-shrink-0">
                <XCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-red-700">
                  Unable to edit document in test and production. Please edit the document in
                  development.
                </p>
              </div>
            </div>
          </div>
        )}
        <div className="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
          <div className={"py-5"}>
            <SidebarTextbox
              name="type"
              value={document.type}
              label={"Type"}
              onChange={onChange}
              disabled={!canEdit}
              error={errors.type}
            />
            <SidebarTextbox
              name="name"
              value={document.name}
              label={"Name"}
              onChange={onChange}
              disabled={!canEdit}
              error={errors.name}
            />

            <SidebarTextbox
              name="topic"
              value={document.topic}
              label={"Topic"}
              onChange={onChange}
              disabled={!canEdit}
              error={errors.topic}
            />

            <SidebarSelect
              name="category"
              value={document.category}
              label={"Category"}
              onChange={onChange}
              disabled={!canEdit}
              error={errors.category}
              options={
                [ { name: "Select Item", value: "" }, ].concat(categoriesOptions)}
            />
          </div>
          <div className={"py-5"}>
            <SidebarSelect
              name="retentionPolicy"
              value={document.retentionPolicy}
              label={"Retention Policy"}
              disabled={!canEdit}
              onChange={onChange}
              error={errors.retentionPolicy}
              options={[
                { name: "Select Item", value: "" },
                { name: "BeneficiaryChange", value: "BeneficiaryChange" },
                { name: "DC_24Hours", value: "DC_24Hours" },
                { name: "DC_OneMonth", value: "DC_OneMonth" },
                { name: "DC_TwoMonths", value: "DC_TwoMonths" },
                { name: "DC_SixMonths", value: "DC_SixMonths" },
                { name: "DC_TenYears", value: "DC_TenYears" },
                { name: "DeathHandling", value: "DeathHandling" },
                { name: "EarlyWithDrawal", value: "EarlyWithDrawal" },
                { name: "FinalPay", value: "FinalPay" },
                { name: "InsuranceChange", value: "InsuranceChange" },
                { name: "MaxLength", value: "MaxLength" },
                { name: "Move", value: "Move" },
                { name: "On Signal", value: "On Signal" },
                { name: "Repurchase", value: "Repurchase" },
                { name: "Transfer", value: "Transfer" },
                { name: "ZeroLetter", value: "ZeroLetter" },
              ]}
            />
            <SidebarSelect
              name="adminCompany"
              value={document.adminCompany}
              label={"Admin Company"}
              disabled={!canEdit}
              onChange={onChange}
              error={errors.adminCompany}
              options={[
                { name: "Select Item", value: "" },
                { name: "Depends on Doc Topic", value: "Depends on Doc Topic" },
                { name: "Depends on Doc Topic and Kovis Archive", value: "Depends on Doc Topic and Kovis Archive" },
                { name: "Euroben", value: "Euroben" },
                { name: "From INCA-XML", value: "From INCA-XML" },
                { name: "SPP", value: "SPP" },
                { name: "SPP Konsult", value: "SPP Konsult" },
                { name: "SPP Spar", value: "SPP Spar" },
                { name: "Storebrand Fonder", value: "Storebrand Fonder" },
              ]}
            />
            <SidebarSelect
              name="internalOnly"
              value={document.internalOnly}
              label={"Internal Only"}
              disabled={!canEdit}
              onChange={onChange}
              error={errors.internalOnly}
              options={[
                { name: "Select Item", value: "" },
                { name: "Yes", value: "Yes" },
                { name: "No", value: "No" },
              ]}
            />
            <SidebarSelect
              name="changesAllowed"
              value={document.changesAllowed}
              label={"Changes Allowed"}
              disabled={!canEdit}
              onChange={onChange}
              error={errors.changesAllowed}
              options={[
                { name: "Select Item", value: "" },
                { name: "Yes", value: "Yes" },
                { name: "No", value: "No" },
              ]}
            />
            <SidebarSelect
              name="plexmode"
              value={document.plexmode}
              label={"Plexmode"}
              disabled={!canEdit}
              onChange={onChange}
              error={errors.plexmode}
              options={[
                { name: "Select Item", value: "" },
                { name: "Duplex", value: "Duplex" },
                { name: "Simplex", value: "Simplex" },
              ]}
            />
            <SidebarSelect
              name="colormode"
              value={document.colormode}
              label={"Colormode"}
              disabled={!canEdit}
              onChange={onChange}
              error={errors.colormode}
              options={[
                { name: "Select Item", value: "" },
                { name: "Colour", value: "Colour" }
              ]}
            />
            <SidebarSelect
              name="postmode"
              value={document.postmode}
              label={"Postmode (A/B)"}
              disabled={!canEdit}
              onChange={onChange}
              error={errors.postmode}
              options={[
                { name: "Select Item", value: "" },
                { name: "A", value: "A" },
                { name: "B", value: "B" },
              ]}
            />
            <SidebarSelect
              name="distributionMethod"
              value={document.distributionMethod}
              label={"Distribution method"}
              disabled={!canEdit}
              onChange={onChange}
              error={errors.distributionMethod}
              options={[
                { name: "Select Item", value: "" },
                { name: "ByCustomerPreference", value: "ByCustomerPreference" },
                { name: "MandatoryPrint", value: "MandatoryPrint" },
                { name: "MustReachCustomer", value: "MustReachCustomer" },
              ]}
            />
            <SidebarTextArea
              name="comment"
              value={document.comment}
              onChange={onChange}
              label={"Comments"}
              disabled={!canEdit}
            />
          </div>
        </div>
      </div>
      </div>

      {/* Action buttons */}
      <div className="flex-shrink-0 px-4 border-t bg-gray-50 border-gray-200 py-5 sm:px-6">
        <div className="space-x-3 flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 "
            onClick={() => setOpen(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-pink hover:bg-brand-pink-hover"
            onClick={handleSubmit}
            disabled={!canEdit}
          >
            Ok
          </button>
        </div>
      </div>
    </>
  );
}
